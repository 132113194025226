<template>
    <div class="pageBox">
        <PageComponent publicTemplateName="学习未取证" :publicStatus="1600"></PageComponent>
    </div>
</template>

<script>
import PageComponent from "./PageComponent"
export default {
    components: {PageComponent},
    data() {
        return{
            
        }
    },
    methods: {
        
    },
    watch: {
        
    },
    mounted() {
        
    },
}
</script>

<style lang="stylus" scoped>
    
</style>